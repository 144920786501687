.Notfound {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* TEST */
.incident-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-bottom: 0;
}
.incident-switch input[type="checkbox"] {
  display: none;
}
.incident-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.incident-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.incident-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(25px);
  background-color: #fff;
}

.incident-switch input[type="checkbox"] + .switch::before {
  background-color: #fff;
}

.banner-switch .switch {
  background-color: #090f30;
}

.banner-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 15px;
  height: 15px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.banner-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(10px) !important;
}

.banner-switch input[type="checkbox"]:checked + .switch {
  background-color: #090f30 !important;
} 

.incident-switch input[type="checkbox"]:checked + .switch {
  background-color: #b98772;
} 

.avtiveIncident {
  display: flex;
  align-items: center;
}

.avtiveIncident p {
  margin: 0;
  white-space: nowrap;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 400;
}

.required {
  color: red;
}

.selected-type {
  font-weight: 700 !important;
}