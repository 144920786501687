.bg-primary {
    background-color: var(--primary) !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.login__logo img {
    width: 200px;
    object-fit: contain;
}

.login__content p {
    color: var(--secondary);
}

.login__content h1 {
    color: #fff;
}

.hero-wrap {
    background-image: url('../public/assets/img/login.jpg');
    background-position: center;
    background-size: cover;
    position: relative;
}

.hero-wrap::after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: #0a0f31e0;
}

.login-index {
    z-index: 9;
}

.preview__img img.preview {
    width: 100%;
    height: 100px;
    object-fit: contain;
    margin-bottom: 10px;
    background: #f6f6f6;
    padding: 10px 0;
}

.deleteModal h2 {
    text-align: center;
    font-size: 22px;
}

.deleteModal .modal-body {
    border: none;
    padding: 0;
}

.deleteModal .modal-content {
    padding: 40px;
    text-align: center;
    border-radius: 10px;
}

.deleteModal .modal-content .modal-footer {
    justify-content: center;
    border: none;
}

.jodit-status-bar {
    visibility: hidden;
}

span.formError {
    color: #f85359;
    line-height: 2.5;
}

.form__heading {
    background: #b98772;
    color: #fff;
    padding: 10px;
    margin-bottom: 20px;
}

.form__heading h4 {
    margin: 0;
    color: #ffff;
    font-size: 14px;
}

.paginationStyle .pagination {
    justify-content: end;
}

.paginationStyle .pagination a.page-link {
    background: #bebaba;
    border-radius: 4px !important;
    margin: 0 2px;
    color: #fff;
    cursor: pointer;
}

.paginationStyle .pagination li.page-item.pageActive a.page-link {
    background: #b98772;
}

.paginationStyle .pagination li.page-item.disabled a.page-link {
    background: #bebaba;
    color: #ffff;
}

.noData-found {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noData-found h3 {
    margin: 0;
    font-size: 18px;
    color: #b98772;
}


@media only screen and (max-width: 600px) {
    .min-vh-100 {
        min-height: 50vh !important;
    }

}

.add_tag_line {

}

.add_tag_line label {
    margin-bottom: 20px;
}