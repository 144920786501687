.add_news .thumbnail_preview {
    margin-bottom: 16px;
    height: 150px;
}

.add_news .files {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    flex-wrap: wrap;
}

.add_news .files:empty {
    display: none;
}

.add_news .files img {
    width: 150px;
    height: 110px;
    object-fit: cover;
}

.add_news .files>span {
    position: relative;
}

.add_news .files .close_icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    position: absolute;
    cursor: pointer;
    background-color: #ff3b3b;
    border-radius: 50%;
    right: 7px;
    top: 7px;
    color: #fff;
    font-size: 9px;
}

.add_news .moreFiles {
    background-color: #b98772;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    margin: 0;
    cursor: pointer;
}

tr.published {
    color: #b98772;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    padding: 0.5rem;
    box-sizing: content-box;
    right: 0;
    top: 0;
    font-size: 18px;
}